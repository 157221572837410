import { useColorMode } from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MantineProvider } from "@mantine/core";
import { SpotlightProvider } from "@mantine/spotlight";

const SpotlightProviderComponent = ({ children }: any) => {
  const { colorMode } = useColorMode();
  return (
    <MantineProvider theme={{ colorScheme: colorMode }}>
      <SpotlightProvider
        actions={[]}
        topOffset={50}
        centered={false}
        limit={10}
        highlightQuery
        transition="scale"
        searchPlaceholder="Search..."
        searchIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        styles={{ actions: { maxHeight: 700, overflowY: "auto" } }}
      >
        {children}
      </SpotlightProvider>
    </MantineProvider>
  );
};
export default SpotlightProviderComponent;
