import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ChakraProvider, IconButton, useColorMode } from "@chakra-ui/react";
import { ParallaxProvider } from "react-scroll-parallax";
import theme from "../theme";
import { RecoilRoot } from "recoil";
import { MoonStars, Sun } from "phosphor-react";
import { SWRConfig } from "swr";
import Head from "next/head";
import gqlFetcher from "../gql-request";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "rsuite/dist/rsuite.min.css";
import "../styles/globals.css";
config.autoAddCss = false;
import { gql } from "graphql-request";
import SpotlightProviderComponent from "../components/SpotlightProviderComponent";
import "@fontsource/noto-sans";
import "@fontsource/open-sans";
import "@fontsource/roboto";
import "@fontsource/ubuntu";
import "@fontsource/lato";
import "@fontsource/montserrat";
import { useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "../styles/primeReactOverrides.scss";
import PrimeReact from "primereact/api";
import "primeicons/primeicons.css";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import NextNProgress from "nextjs-progressbar";
import Script from "next/script";
import calendar from "dayjs/plugin/calendar";
import * as gtag from "../utils/gtag";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useRouter } from "next/router";
import { usePartnerCreation } from "../utils/tools";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
PrimeReact.ripple = true;
/* eslint-disable react-hooks/exhaustive-deps */
const queryClient = new QueryClient();
function MyApp({ Component, pageProps }: AppProps) {
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isBetween);
  dayjs.extend(calendar);
  dayjs.extend(advancedFormat);
  const partnerCreation = usePartnerCreation();
  console.debug(partnerCreation);

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <SWRConfig
        value={{
          fetcher: (resource, init) =>
            fetch(resource, init).then((res) => res.json()),
        }}
      >
        <Head>
          <meta name="description" content="Generated by create next app" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <link rel="icon" href="newEDark.svg" type="image/svg+xml" />
        </Head>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=G-9380LCPZ3M`}
          id="Gtag1"
        />
        <Script
          id="Gtag2"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9380LCPZ3M', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
        <RecoilRoot>
          <ChakraProvider theme={theme} portalZIndex={999}>
            <SpotlightProviderComponent>
              <ParallaxProvider>
                <NextNProgress color="#F6921E" height={5} />
                <Component {...pageProps} />
              </ParallaxProvider>
            </SpotlightProviderComponent>
          </ChakraProvider>
        </RecoilRoot>
      </SWRConfig>
    </QueryClientProvider>
  );
}

export default MyApp;
