import axios from "axios";
import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { partnerCreationAtom, businessCreationAtom } from "../atoms";

export const usePartnerCreation = () => {
  const [partnerCreationInfo, setPartnerCreationInfo] =
    useAtom(partnerCreationAtom);
  const createPartner = async (businessId?: number, rest?: any) => {
    const partnerCreation = await axios
      .post("/api/createPartner", {
        ...partnerCreationInfo.onboardForm.attributes,
        businessId,
        ...rest,
        onboardForm: partnerCreationInfo.onboardForm.id,
      })
      .catch((err) => console.log(err));

    return partnerCreation;
  };

  const updatePartner = async (id: string, data: any) => {
    const res = await axios
      .post("/api/updatePartner", {
        ...data,
      })
      .catch((err) => console.log(err));

    return res;
  };

  return {
    partnerCreationInfo,
    setPartnerCreationInfo,
    createPartner,
    updatePartner,
  };
};
export const useBusinessCreation = () => {
  const [businessCreationInfo, setBusinessCreationInfo] =
    useAtom(businessCreationAtom);
  const createBusiness = async () => {
    interface BusinessCreation {
      createBusiness: {
        data: {
          id: string;
          attributes: {
            firstName: string;
            lastName: string;
            organizationName: any;
            locationId: number;
            email: string;
            active: any;
            createdAt: string;
            updatedAt: string;
            phone: string;
            businessId: number;
            business_type: any;
            CAID: any;
            pin: any;
          };
        };
      };
    }
    const data = await axios.post("/api/createStrapiBusiness", {
      ...businessCreationInfo,
    });

    return data.data as BusinessCreation;
  };

  return {
    businessCreationInfo,
    setBusinessCreationInfo,
    createBusiness,
  };
};

// export const usePartnerConnection = (
//   partnerCode?: String,
//   partnerId?: number
// ) => {
//   const query = useQuery(
//     ["partnerDetails", partnerCode, partnerId],
//     async () => {
//       const partnerDetails = await axios
//         .post(`/api/getPartnerDetails`, {
//           partnerCode,
//           id: partnerId,
//         })
//         .catch((err) => console.log(err));

//       return partnerDetails;
//     }
//   );
// };
