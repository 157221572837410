import { atom } from "jotai";
import { PartnerOnboardForm, StrapiBusiness } from "./types";
import { atomWithStorage } from "jotai/utils";
interface PartnerCreationTypes {
  onboardForm: PartnerOnboardForm;
  business: StrapiBusiness;
  partnerData: {};
}
export const partnerCreationAtom = atomWithStorage<PartnerCreationTypes>(
  "partnerCreationInfo",
  {} as PartnerCreationTypes
);

export const businessCreationAtom = atomWithStorage<StrapiBusiness>(
  "strapiBusiness",
  {} as StrapiBusiness
);

// export const partnerConnectionAtom = atomWithStorage<>()
