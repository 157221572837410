// 1. import `extendTheme` function
import {
  baseStyle,
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import "@fontsource/open-sans";
import "@fontsource/raleway";
import "@fontsource/lato";
// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme(
  {
    initialColorMode: "light",
    useSystemColorMode: false,
    colors: {
      brand: {
        "50": "#ECF0F8",
        "100": "#CBD5EB",
        "200": "#AABADF",
        "300": "#88A0D2",
        "400": "#6785C6",
        "500": "#2F5DAA",
        "600": "#385594",
        "700": "#2A3F6F",
        "800": "#1C2A4A",
        "900": "#0E1525",
        "1000": "#080D16",
        red: "#c3382d",
        green: "#3C8D50",
        blue: "#4264af",
        orange: "#F7941d",
        cement: "#D0D2D3",
        steel: "#6D6E70",
        carbon: "#231f20",
      },
      jty: {
        100: "#49AB62",
        200: "#3C8D50",
        300: "#378149",
        400: "#317241",
        500: "#255631",
        600: "#1f4729",
        700: "#122B18",
        800: "#0C1D10",
      },
      econic: {
        "50": "#ECF0F8",
        "100": "#CBD5EB",
        "200": "#AABADF",
        "300": "#88A0D2",
        "400": "#6785C6",
        "500": "#4264af",
        "600": "#385594",
        "700": "#2A3F6F",
        "800": "#1C2A4A",
        "900": "#0E1525",
        "1000": "#080D16",
        red: "#c3382d",
        green: "#4b8c4f",
        blue: "#4264af",
        orange: "#F7941d",
      },
      darkBg: "#0E1011",
      // lightbg:
      colorModeText: "#000",
    },
    styles: {
      global: (props: any) => ({
        body: {
          // fontFamily: "ubuntu",
          color: mode("#333333", "whiteAlpha.900")(props),
          bg: mode("#FFF", "brand.900")(props),
          lineHeight: "base",
        },
        "&::-webkit-scrollbar": {
          backgroundColor: mode("#EEF2F7", "#212324")(props),
        },
      }),
    },
    fonts: {
      body: "Open Sans, Arial",
      heading: "Open Sans, Arial",
    },
    components: {
      Input: {
        defaultProps: {
          focusBorderColor: "brand.500",
          errorBorderColor: "red.400",
        },
      },
      Radio: {
        defaultProps: {
          colorScheme: "brand",
        },
      },
      Tooltip: {
        variants: {
          brand: (props: any) => ({
            bg: "#4264af",
            color: "#FFF",
          }),
        },
        baseStyle: (props: any) => ({
          bg: props.colorMode == "dark" ? "brand.800" : "brand.50",
          color: "#FFF",
          borderRadius: "md",
        }),
      },
      // Link: {
      //   baseStyle: {
      //     _hover: { color: "brand.green" },
      //   },
      // },
    },

    shadows: {
      outline: "0 0 0 2px #4264af",
    },
    config,
  }
  // withDefaultColorScheme({ colorScheme: "brand", components: [""] })
);

export default theme;
